import React from "react";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { parsePageType } from "providers/actions/parsePageType";
import { fetchApi } from "providers/actions/util";
import { loadByCid } from "providers/actions/loadByCid";
import { getContentIdFromSlug } from "providers/Article/utils";
import get from "lodash/get";
import { isArray } from "lodash";
import { parseCookies } from "nookies";
import { getSiteData } from "../utils/site";
import { debugTools } from "../utils/debugTools";
import { loadNavigation } from "../utils/loadNavigation";
import { getDepFromNav } from "../utils/getDepFromNav";
import InterstitialAd from "components/Ads/AdsInterstitial.index";

// SSR Providers
const ServerSidePropsProvider = dynamic(
  () =>
    import(
      /* webpackChunkName: 'module-providers-ssrprops' */
      "providers/ServerSideProps"
    ),
  { ssr: true }
);
const SeoProvider = dynamic(
  () =>
    import(
      /* webpackChunkName: 'module-providers-seo' */
      "providers/Seo"
    ),
  { ssr: true }
);
// Client Providers
const ArticleProvider = dynamic(
  () =>
    import(
      /* webpackChunkName: 'module-providers-page' */
      "providers/Article"
    ),
  { ssr: false }
);
const ArticleScrollProvider = dynamic(
  () =>
    import(
      /* webpackChunkName: 'module-providers-page' */
      "providers/ArticleScroll"
    ),
  { ssr: false }
);
const PianoInitProvider = dynamic(
  () =>
    import(
      /* webpackChunkName: 'module-providers-page' */
      "providers/Piano/PianoInit"
    ),
  { ssr: false }
);
const PagesProvider = dynamic(
  () =>
    import(
      /* webpackChunkName: 'module-providers-page' */
      "providers/Pages"
    ),
  { ssr: false }
);
const LayoutProvider = dynamic(
  () =>
    import(
      /* webpackChunkName: 'module-providers-page' */
      "providers/Layout"
    ),
  { ssr: false }
);
const FilterProvider = dynamic(
  () =>
    import(
      /* webpackChunkName: 'module-providers-page' */
      "providers/Filter"
    ),
  { ssr: false }
);
const FilterDataProvider = dynamic(
  () =>
    import(
      /* webpackChunkName: 'module-providers-page' */
      "providers/FilterData"
    ),
  { ssr: false }
);
const RenderSlots = dynamic(
  () =>
    import(
      /* webpackChunkName: 'module-components-base_slots' */
      "components/Base/Base.Slots"
    ),
  { ssr: false }
);
const SessionProvider = dynamic(
  () =>
    import(
      /* webpackChunkName: 'module-providers-page' */
      "providers/Session"
    ),
  { ssr: false }
);
const ScriptsProvider = dynamic(
  () =>
    import(
      /* webpackChunkName: 'module-providers-layout' */
      "providers/Scripts"
    )
);

const PageSlug = ({ serverSideProps }) => {
  return (
    <ServerSidePropsProvider serverSideData={serverSideProps}>
      <SeoProvider>
        <ScriptsProvider>
          <InterstitialAd />
          <PagesProvider>
            <LayoutProvider>
              <ArticleProvider>
                <FilterProvider>
                  <FilterDataProvider>
                    <ArticleScrollProvider>
                      <PianoInitProvider>
                        <RenderSlots />
                      </PianoInitProvider>
                    </ArticleScrollProvider>
                  </FilterDataProvider>
                </FilterProvider>
              </ArticleProvider>
            </LayoutProvider>
          </PagesProvider>
        </ScriptsProvider>
      </SeoProvider>
    </ServerSidePropsProvider>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const site_slug = process.env.NEXT_PUBLIC_WEBAPP_CLIENT_SLUG;
  const baseUrl = process.env.NEXTAUTH_URL || ''

  try {
    const api_url = process.env.NEXT_PUBLIC_WEBAPP_API_SITE_ADDRESS;

    // Disable until better performance
    // const { res, req } = context;
    // const host = req.headers.host;
    // const protocol = req.headers['x-forwarded-proto'] || 'http';
    // const originalUrl = req.url || '';
    //
    // // Redirect www to non-www
    // if (host && host.startsWith('www.')) {
    //   const nonWwwHost = host.replace('www.', '');
    //   const destination = `${protocol}://${nonWwwHost}${originalUrl}`;
    //   res.writeHead(301, { Location: destination });
    //   res.end();
    //   return { props: {} }
    // }

    const site_ext_id = getSiteData(site_slug).name;
    const cookies = parseCookies(context);
    const sessionData = cookies?.sessionData ? JSON.parse(cookies.sessionData) : {};

    const slug = context?.query?.slug || [];
    debugTools.log('[DEBUG] ([...slug]) slug in getServerSideProps: ', slug);

    const req_host = context.req.headers.host;
    const req_url: any = context?.req?.url;
    const req_secure_domain = `https://${req_host}`;

    const page_type = parsePageType(slug);
    const articleId =
      page_type?.type === "article" && getContentIdFromSlug(null, [req_url]);
    const dept_slug = slug.slice(0, 2);
    const dept_url =
      typeof dept_slug === "string" ? dept_slug : page_type?.departmentSlug;

    const cookiesFromHeader: string | undefined = context?.req?.headers?.cookie;
    const contentIdFromCookie: string = get(
      cookiesFromHeader?.split("polopoly.contentIdsInEdit="),
      "[1]",
      ""
    );
    const contentIdSplitted: string[] = contentIdFromCookie
      ? contentIdFromCookie?.split(".")
      : [];
    const contentIdFromPreview: string = `${get(contentIdSplitted, "[0]")}.${get(
      contentIdSplitted,
      "[1]"
    )}`;
    const preview: { enabled: boolean; contentId: string } = {
      enabled: contentIdFromCookie !== "" && contentIdFromPreview === articleId,
      contentId: contentIdFromCookie,
    };

    debugTools.log(`[DEBUG] ([...slug]) fetchApi: ${api_url}/siteApi/siteData/${site_ext_id}`);
    let reqstart = new Date(Date.now()).getTime();
    const siteData = await fetchApi(`${api_url}/siteApi/siteData/${site_ext_id}`);
    debugTools.log("[DEBUG] ([...slug]) fetchApi.endRequest: ", new Date(Date.now()).getTime() - reqstart);

    let loja: string;

    switch (process.env.NEXT_PUBLIC_PAYWALL_PRODUCT) {
      case 'OPOPULAR':
        loja = 'POPSTORE';
        break;
      case 'JORNAL_DO_TOCANTINS':
        loja = 'JTOSTORE';
        break;
      case 'JORNAL_DAQUI_GO':
        loja = 'DAQUI GO STORE';
        break;
      case 'JORNAL_DAQUI_TO':
        loja = 'DAQUI TO STORE';
        break;
      default:
        loja = 'POPSTORE';
        break;
    }

    let statusPaywall

    try {
      statusPaywall = await fetchApi(`${process.env.NEXT_PUBLIC_WEBAPP_PAYWALL_ADDRESS}/config/${loja}/status-paywall`)
    } catch (error) {
      debugTools.log("[ERROR] ([...slug]) Status Paywall error: ", error);
      statusPaywall = undefined
    }

    if (!page_type) {
      debugTools.log("[DEBUG] ([...slug]) page_type not found: ", slug);

      return {
        redirect: {
          destination: baseUrl,
          statusCode: 301,
        },
      }
    }

    if (!statusPaywall && !!(siteData?.siteData?.pageConfig?.featureRadioSelectPaywall)) {
      siteData.siteData.pageConfig.featureRadioSelectPaywall = 'DESATIVADO';
    }


    if (!siteData.navigation) {
      context.res.statusCode = 404;

      return {
        props: {
          serverSideProps: {
            article: [],
            domain: req_secure_domain,
            navigation: {},
            page: {},
            pageSlots: {},
            preview,
            site: {},
            siteSlots: {},
            siteSlug: site_slug,
          },
        },
      };
    }

    const menuActions = siteData?.navigation?.menuActions;
    const menuSide = siteData?.navigation?.menuSide;

    const safeMenuSide = Array.isArray(menuSide)
      ? menuSide.filter(item => item != null && String(item).trim() !== "")
      : [];

    const menuTop = siteData?.navigation?.menuTop;
    const navigationDataParsed = loadNavigation(
      [menuActions, safeMenuSide, menuTop],
      [siteData?.navigationData],
      siteData?.siteData?.pathSegment
    );
    let deptData = getDepFromNav([siteData.navigationData], dept_url);

    if (!deptData || !deptData.id) {
      deptData = siteData?.siteData;

      // If the department is not found, return 404 (will return the home page, but with 404 status code)
      context.res.statusCode = 404;
    }

    debugTools.log(`[DEBUG] ([...slug]) fetchApi: ${api_url}/siteApi/pageData/${deptData.id.replace("policy:", "")}`);
    let req2start = new Date(Date.now()).getTime();
    const pageData = await fetchApi(
      `${api_url}/siteApi/pageData/${deptData.id.replace("policy:", "")}`
    );
    debugTools.log("[DEBUG] ([...slug]) fetchApi.endRequest: ", new Date(Date.now()).getTime() - req2start);

    if (!pageData || !pageData.pageSlots) {
      context.res.statusCode = 404;

      return {
        props: {
          serverSideProps: {
            article: [],
            domain: req_secure_domain,
            navigation: navigationDataParsed,
            page: deptData,
            pageSlots: {},
            pageType: page_type,
            preview,
            session: sessionData || {},
            site: siteData.siteData,
            siteSlots: siteData.siteSlots,
            siteSlug: site_slug,
          },
        },
      };
    }

    let articleData: Array<any> = [];
    if (page_type?.type === "article") {
      debugTools.log("[DEBUG] ([...slug]) loadByContentList.startRequest: ", articleId, 0);
      let req3start = new Date(Date.now()).getTime();

      const userAgent = context?.req?.headers["user-agent"] || "";
      const device = userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
        ? "MOBILE"
        : "DESKTOP";

      debugTools.log(`[DEBUG] ([...slug]) loadByCid: ${articleId}`);
      const articlelist = await loadByCid(
        articleId,
        api_url,
        sessionData,
        device
      );

      const selectedArticle = isArray(articlelist?.data)
        ? articlelist?.data[0]
        : articlelist?.data || {};

      const relatedArticeList = selectedArticle?.relatedAutoList || [];
      const totalArticleList = [{ ...selectedArticle }, ...relatedArticeList];
      const articles = totalArticleList.length !== 0 ? totalArticleList : [];
      articleData = articles;

      const isValidSites = (selectedArticle?.siteOrigem?.join("|") || "").includes(site_slug);

      // If article not found, response as 404
      if (!!selectedArticle?.error || !isValidSites) {
        context.res.statusCode = 404;

        return {
          props: {
            serverSideProps: {
              article: [],
              domain: req_secure_domain,
              navigation: navigationDataParsed,
              page: deptData,
              pageSlots: pageData.pageSlots,
              pageType: page_type,
              preview,
              session: sessionData || {},
              site: siteData.siteData,
              siteSlots: siteData.siteSlots,
              siteSlug: site_slug,
            }
          },
        };
      }

      debugTools.log("[DEBUG] ([...slug]) loadByContentList.endRequest: ", new Date(Date.now()).getTime() - req3start);
    }

    return {
      props: {
        serverSideProps: {
          article: articleData,
          domain: req_secure_domain,
          navigation: navigationDataParsed,
          page: deptData,
          pageSlots: pageData.pageSlots,
          pageType: page_type,
          preview,
          session: sessionData || {},
          site: siteData.siteData,
          siteSlots: siteData.siteSlots,
          siteSlug: site_slug,
        },
      },
    };
  } catch (error) {

    debugTools.log("[ERROR] ([...slug]) getServerSideProps.error: ", error);
    return {
      redirect: {
        destination: baseUrl,
        statusCode: 301,
      },
    };
  }
};

export default PageSlug;
